var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":_vm.getTitle()}}),(_vm.error)?[_c('v-banner',{attrs:{"single-line":"","elevation":"10"}},[_c('v-icon',{attrs:{"slot":"icon","color":"warning","size":"36"},slot:"icon"},[_vm._v(" mdi-wifi-strength-alert-outline ")]),_c('div',[_vm._v(" There was a problem communicating to the server! "),_c('div',{staticClass:"code"},[_c('strong',[_vm._v(" "+_vm._s(_vm.error)+" ")])])])],1)]:_vm._e(),_c('span',{staticClass:"font-weight-light subtitle-1"},[_c('v-btn',{attrs:{"elevation":"1","color":"blue darken-1","dark":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-keyboard-backspace ")]),_vm._v(" Back ")],1)],1),(_vm.items.length > 0)?[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(" "+_vm._s(_vm.items.length)+" Deals ")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-col',{staticClass:"text-right"},[_c('download-excel',{attrs:{"data":_vm.parsedItems,"type":"csv","name":_vm.fileName}},[_c('v-icon',_vm._g({attrs:{"large":"","color":"green darken-2"}},on),[_vm._v(" mdi-microsoft-excel ")])],1)],1)]}}],null,false,3356979518)},[_c('span',[_vm._v("Download")])])],1)]:_vm._e(),_c('v-card',[_c('date-range',{attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate},on:{"changedStartDate":function($event){return _vm.changedStartDate($event)},"changedEndDate":function($event){return _vm.changedEndDate($event)}}})],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.loadData()}}},[_vm._v(" Update ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"striped_table",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":50,"sort-by":['sold_at'],"sort-desc":true},scopedSlots:_vm._u([{key:"item.sold_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value))+" ")]}},{key:"item.sale_price",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.front_gross",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.back_gross",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.total_profit",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPrice(value))+" ")]}},{key:"item.make",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.year + ' ' + item.make + ' ' + item.model)+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticStyle:{"font-size":"1.2rem"},attrs:{"colspan":"6"}},[_vm._v(" Totals ")]),_c('th',{staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.sumField(_vm.items, 'sale_price', _vm.formatPrice))+" ")]),_c('th',{staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.sumField(_vm.items, 'front_gross', _vm.formatPrice))+" ")]),_c('th',{staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.sumField(_vm.items, 'back_gross', _vm.formatPrice))+" ")]),_c('th',{staticStyle:{"font-size":"1.2rem"}},[_vm._v(" "+_vm._s(_vm.sumField(_vm.items, 'total_profit', _vm.formatPrice))+" ")])])])],2)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Loading "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }