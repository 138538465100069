import axios from 'axios'

export default {
  getVendors (data) {
    return axios.get('/vendors?start_date=' + data.startDate + '&end_date=' + data.endDate)
  },
  getDeals (data) {
    return axios.get('/vendors/' + data.vendor_id + '/deals/?start_date=' + data.startDate + '&end_date=' + data.endDate)
  },
  getNewVendorData () {
    return axios.get('/new_vendor')
  },
}
